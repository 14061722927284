import React from "react";
import { graphql } from "gatsby";
import Layout from "../../../components/layout";

import { Helmet } from "react-helmet";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";
import GitHubIcon from "../../../images/icons/github.svg";
import CodeSandBoxIcon from "../../../images/icons/codesandbox.svg";
import { ExternalLinkIcon } from "@heroicons/react/outline";
import { PlayIcon } from "@heroicons/react/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <b>{text}</b>,
    [MARKS.ITALIC]: (text) => <i>{text}</i>,
    [MARKS.UNDERLINE]: (text) => <u>{text}</u>,
    [MARKS.CODE]: (text) => (
      <p className="mb-4 ml-8 text-2xl font-light italic text-gray-600 ">
        {text}
      </p>
    ),
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <p className="mb-4 leading-relaxed">{children}</p>
    ),
    [INLINES.HYPERLINK]: (node, children) => (
      <a
        className="underline underline-offset-2 decoration-black dark:decoration-[#94a3b8] decoration-2 dark:text-slate-400"
        href={node.data.uri}
      >
        {children}
      </a>
    ),
    [INLINES.EMBEDDED_ENTRY]: (node, children) => {
      if (node.data.target.sys.type === "Entry") {
        return (
          <a
            className="underline underline-offset-2 decoration-black dark:decoration-[#94a3b8] decoration-2 dark:text-slate-400"
            href={`/portfolio/${node.data.target.slug}`}
          >
            {" "}
            {node.data.target.title}
          </a>
        );
      }
    },
  },
};

function Index({ data }) {
  const path =
    typeof location !== "undefined" && location.pathname
      ? location.pathname
      : "";
  return (
    <Layout location="/shared/play/">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Playground</title>
        <meta name="description" content="Ole Lillebos Playground" />
        <html lang="en" amp />
        <link rel="canonical" href="https://www.olelillebo.com/playground/" />
      </Helmet>
      <div className="bg-gray-50 dark:bg-gray-900 dark:text-[#94a3b8] pt-16 -mt-16">
        <div className="max-w-screen-xl mx-auto px-4 pt-6 flex justify-center flex-col items-center">
          <h2 className="text-lg lg:text-xl font-bold leading-snug text-gray-900 dark:text-white my-2">
            Prototype / proof of concept examples
          </h2>
          <p className="text-base font-light max-w-screen-sm flex text-center">
            Ever since building my first websites with FrontPage in the 90s,
            coding and its world of problem-solving have been a passion of mine.
            With a background in Computer Science and entering the world of UX
            when Photoshop and Balsamiq still ruled, quick and dirty code
            mockups became my go-to method for creating more realistic
            prototypes and proof of concepts.
          </p>
          <p className="text-base font-light max-w-screen-sm flex text-center mt-4">
            Driven by that sensation of finally getting that overly ambitious
            idea to work, countless off-work hours have been spent learning the
            latest and buzzing tech stack - be it jQuery or React. The below
            list showcases a few of my prototypes and proof of concepts made
            over the years - some hackier than the others, but still serving its
            purpose: adding that realistic touch to any pitch or user test they
            featured in.
          </p>
        </div>
        <div className="max-w-screen-xl mx-auto py-6 grid grid-cols-1 md:grid-cols-1 gap-8 lg:gap-40 lg:pt-20">
          {data.codeExamples.nodes.map((project, index) => (
            <div
              key={project.id}
              className={classNames(
                "overflow-hidden flex items-center w-full flex-col-reverse lg:flex-row px-4 pt-4 lg:pt-0 bg-white dark:bg-gray-800  lg:bg-transparent lg:dark:bg-transparent ",
                index % 2 == 0 ? "" : "lg:flex-row-reverse"
              )}
            >
              <div
                className={classNames(
                  "flex flex-col w-full lg:w-2/6",
                  index % 2 == 0 ? "lg:pr-8" : "lg:pl-8"
                )}
              >
                <h2 className="text-lg lg:text-xl font-bold leading-snug text-gray-900 dark:text-white my-2">
                  {project.title}
                </h2>
                <div className="text-sm lg:text-md text-gray-900 dark:text-slate-400">
                  {renderRichText(project.description, options)}
                </div>

                {project.githuburl ? (
                  <a
                    key={project.githuburl}
                    href={project.githuburl}
                    target="_blank"
                    className="flex pb-4 group w-full items-center transition duration-150 ease-in-out"
                  >
                    <GitHubIcon
                      aria-hidden="true"
                      class="h-8 w-8 flex  dark:bg-white dark:rounded-full dark:p-0.5"
                    />

                    <div className="mx-2">
                      <div className="text-sm dark:text-white font-medium group-hover:text-transparent group-hover:transition group-hover:bg-clip-text group-hover:bg-gradient-to-br group-hover:from-[#49b6ff] group-hover:to-interaction">
                        See on GitHub
                      </div>
                    </div>
                    <ExternalLinkIcon className="h-6 w-6 group-hover:animate-wiggle" />
                  </a>
                ) : (
                  ""
                )}
                {project.codesandboxurl ? (
                  <a
                    key={project.codesandboxurl}
                    href={project.codesandboxurl}
                    target="_blank"
                    className="flex pb-4 group w-full items-center transition duration-150 ease-in-out"
                  >
                    <CodeSandBoxIcon
                      aria-hidden="true"
                      class="h-8 w-8 flex dark:bg-white dark:rounded-full dark:p-0.5"
                    />

                    <div className="mx-2">
                      <div className="text-sm dark:text-white font-medium group-hover:text-transparent group-hover:transition group-hover:bg-clip-text group-hover:bg-gradient-to-br group-hover:from-[#49b6ff] group-hover:to-interaction">
                        View CodeSandBox
                      </div>
                    </div>
                    <ExternalLinkIcon className="h-6 w-6 group-hover:animate-wiggle" />
                  </a>
                ) : (
                  ""
                )}

                {project.demourl ? (
                  <a
                    key={project.demourl}
                    href={project.demourl}
                    target="_blank"
                    className="flex pb-4 group w-full items-center transition duration-150 ease-in-out"
                  >
                    <PlayIcon className="h-8 w-8 dark:text-black dark:bg-white dark:rounded-full dark:p-0.5 " />

                    <div className="mx-2">
                      <div className="text-sm dark:text-white font-medium group-hover:text-transparent group-hover:transition group-hover:bg-clip-text group-hover:bg-gradient-to-br group-hover:from-[#49b6ff] group-hover:to-interaction">
                        Try it out
                      </div>
                    </div>
                    <ExternalLinkIcon className="h-6 w-6 group-hover:animate-wiggle " />
                  </a>
                ) : (
                  ""
                )}
              </div>
              <div className="flex w-full lg:w-4/6 h-auto bg-gray-100 mb-4 lg:mb-8 dark:bg-gray-800 shadow-lg dark:lg:shadow-interaction rounded-xl">
                <video className=" rounded-xl" muted autoPlay loop>
                  <source src={project.cover.file.url} type="video/mp4" />
                </video>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  {
    codeExamples: allContentfulCodeExample(
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        id
        githuburl
        codesandboxurl
        demourl
        title
        date(formatString: "MMM YYYY")
        description {
          raw
          references {
            ... on ContentfulProject {
              contentful_id
              __typename
              title
              slug
            }
          }
        }
        cover {
          file {
            url
          }
        }
      }
    }
  }
`;

export default Index;
